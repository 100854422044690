import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import logo from './logo.svg';
import Reativar from './components/Login/Reativar';
import { SSL } from './scripts/SSL';
import './App.css';





function App() {
  const [pageLoading, setPageLoading] = useState(true);
  const [complete, setComplete] = useState(false);


  useEffect(() => {
    //SSL.ToHTTPS();
  }, []);


  const HandlerSuccess = () => {
    setComplete(true);
  }





  return (
    <div className="App">
      <br />
      <div className="painel">
        <div className="logo">
            <img src="/img/logo.png" />
            <a href="/whatsnap.rar" target="_blank"><img src="/img/download.png" /></a>
        </div>
        {complete ? (<div>
          <br /><br /><center><span className="topTitle"><h2>Assinatura Concluída!</h2></span></center><br /><br /><br /><br />
        </div>) : (<Reativar onSuccess={HandlerSuccess} Title="Contratar um plano"/>)}
        
      </div>
      <br />
      <center>
            Whatsnap. &copy; 2024 - Recomendado com Google Chrome - <i className="fa fa-envelope-o"></i> <a>contato@lifequery.org</a>
        </center>
      <br />
    </div>
  );
}

export default App;
