/* eslint-disable no-loop-func */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { RequestGet, RequestPost, Request } from '../../scripts/Request.js';
import InfiniteScroll from "react-infinite-scroll-component";
import StringMask from 'string-mask';
import $ from 'jquery';
import moment from 'moment';
import './FormGenerator.css';
import { UnCurrency, Currency, Number } from '../../scripts/StringUtils';
import { Wait, WaitObject, Sleep } from '../../scripts/Wait.js';

window.currentElementFocus = undefined;


let wait_form;
let datalist_timer = [];
let datalist_result = [];

export const FormGenerator = forwardRef((props, ref) => {
    const [fields, setFields] = useState([]);
    const [source, setSource]= useState([]);
    const [mtoken, setToken]= useState("");
    const _loadedRef = useRef(false);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const _stateMachineRef = useRef("idle");
    const _filledRef = useRef(false);
    const _data = useRef([]);
    const [cdn, setCdn] = useState('');

    //#region Effects
    useEffect(() => {
        //window.$(".numeric").numeric({ decimal : ",",  negative : true, scale: 3 });

        /*window.$(document).on("input", ".decimal-number", function (e) {
            this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\.,*)\./g, '$1');
        });*/

        Init();

        return () => {
           clearInterval(wait_form);
        }
    }, []);
    //#endregion Effects


    //#region Ref
    useImperativeHandle(ref, (url, args, token) => ({
        async Post(url, args, token = "") { return Post(url, args, token) },
        async Test(url, args, token = "") { return Test(url, args, token) },
        async Select(field, options, url = "", method = "GET",  args = "", token = "") { return Select(field, options, url, method, args, token) },
        async SelectArray(field, options, url = "", method = "GET",  args = "", token = "") { return SelectArray(field, options, url, method, args, token) },
        async Load(data, field = "", value = "", type = "insert") { return Load(data, field, value, type) },
        async Value(field, value) { return Value(field, value) },
        GetObject(field) { return GetObject(field) },
        SetObject(field, objectValue) { return SetObject(field, objectValue)},
        GetValue(field) { return GetValue(field) },
        GetDataValue(field) { return GetDataValue(field) },
        async GetOptions(field) { return GetOptions(field) },
        GetData() { return PreProcFields([...fields]) },
        IsLoaded() { return IsLoaded()},
        DisableFields(target_fields, enabled) { return DisableFields(target_fields, enabled) },
        HideFields(target_fields, enabled) { return HideFields(target_fields, enabled) },
        Clear() { return Clear()},
        RequiredValidation() { return RequiredValidation() },
        Push(array_name, count) { return Push(array_name, count)},
        Remove(array_name) { return Remove(array_name)},
        ArrayLength(array_name) { return ArrayLength(array_name)},
        DataLength(array_name) { return DataLength(array_name)},
        CDN(cdn_prefix) { setCdn(cdn_prefix) }
        
    }));
    //#endregion Ref


    //#region Actions
    const DisableFields = (target_fields, enabled = false) => {
        let _fields = [...fields];

        if (target_fields) {
            for(let i=0; i<_fields.length; i++){
                for(let j=0; j<target_fields.length; j++){
                    if(target_fields[j] === _fields[i].name) {
                        _fields[i].disabled = !enabled;
                    }
                }
            }
        } else {
            for(let i=0; i<_fields.length; i++){
                 _fields[i].disabled = !enabled;
                 //$("#" + _fields[i].name).prop("disabled", !enabled);
            }
        }

        setFields(_fields);
    }


    const HideFields = (target_fields, enabled = false) => {
        let _fields = [...fields];

        if (target_fields) {
            for(let i=0; i<_fields.length; i++){
                for(let j=0; j<target_fields.length; j++){
                    if(target_fields[j] === _fields[i].name) {
                        _fields[i].visible = enabled;
                    }
                }
            }
        } else {
            for(let i=0; i<_fields.length; i++){
                 _fields[i].visible = enabled;
            }
        }

        setFields(_fields);
    }


    const IsLoaded = () => {
        let _fields = [...fields];
        return _fields.length > 0;
    }


    const Post = async (url, args, token = "") => {
        let _fields = [...fields];
        //console.log(_fields);
        //_fields = _fields.filter(f => f.visible !== false && f.type.view !== "button");
        _fields = _fields.filter(f => f.type.view !== "button");
        //console.log(_fields);

        let arrays = [];
        let array_index = _fields.findIndex(f => f.array !== undefined);

        while(array_index > -1) {
            let array_name = _fields[array_index].array;
            let array_data = _fields.filter(f => f.array === array_name);

            let out = array_data.reduce(function (p, c) {
                if (!p.some(function (el) { return el.name.substr(0, el.name.lastIndexOf("_")) === c.name.substr(0, c.name.lastIndexOf("_")); })) p.push(c);
                return p;
            }, []);

            arrays.push({name: array_name, data: array_data, len: out.length})
            _fields.splice(array_index, array_data.length)
            array_index = _fields.findIndex(f => f.array !== undefined);
        }

        //console.log(_fields);

        _fields = PreProcFields(_fields);
        _fields = (args != null) ? Object.assign(_fields, args) : _fields;

        console.log(_fields);

        let array = [];

        arrays.map((item, i) => {
            let fieldObj = {};
            array[item.name] = [];

            item.data.map((field, j) => {
                let field_name = field.name;
                let p1 = field_name.lastIndexOf("_");

                if(p1 > -1) {
                    field_name = field_name.substr(0, p1);
                }

                if(j%item.len === 0) {
                    fieldObj = {};
                }

                fieldObj = Object.assign({[field_name]: (field.value)}, fieldObj);

                if(j%item.len === item.len - 1) {
                    array[item.name].push(fieldObj);
                }
            })

           _fields = Object.assign({[item.name] : array[item.name]}, _fields);
        });

        
        //console.log(_fields);

        return await Promise.resolve(new Request().Run(url, "POST", _fields, token))
            .then((data) => {
                return data;
            });
    }


    const Test = async (url, args, token = "") => {
        let _fields = [...fields];
        //_fields = _fields.filter(f => f.visible !== false && f.type.view !== "button");
        _fields = _fields.filter(f => f.type.view !== "button");
        console.log(_fields);

        let arrays = [];
        let array_index = _fields.findIndex(f => f.array !== undefined);

        while(array_index > -1) {
            let array_name = _fields[array_index].array;
            let array_data =  _fields.filter(f => f.array === array_name);

            let out = array_data.reduce(function (p, c) {
                if (!p.some(function (el) { return el.name.substr(0, el.name.lastIndexOf("_")) === c.name.substr(0, c.name.lastIndexOf("_")); })) p.push(c);
                return p;
            }, []);


            arrays.push({name: array_name, data: array_data, len:  out.length})
            _fields.splice(array_index, array_data.length)
            array_index = _fields.findIndex(f => f.array !== undefined);
        }

        _fields = PreProcFields(_fields);
        _fields = (args != null) ? Object.assign(_fields, args) : _fields;

        arrays.map((item, i) => {
            let array = [];
            let fieldObj = {};

            item.data.map((field, j) => {
                let field_name = field.name;
                
                let p1 = field_name.lastIndexOf("_");
                if(p1 > -1) {
                    field_name = field_name.substr(0, p1);
                }

                if(j%item.len === 0) {
                    fieldObj = {};
                }

                fieldObj = Object.assign({[field_name]: field.value}, fieldObj);
                
                if(j%item.len === item.len - 1) {
                    array.push(fieldObj);
                }
            });


            _fields = Object.assign({[item.name] : array }, _fields);
        });

        console.log(_fields);
     
    }


    const Select = async (field, options, url, method, args, token) => {
        try {
            let _fields = [...fields];
            let _field_arr = _fields.filter( f => { return f.name && f.name === field});

            if(_field_arr === undefined || _field_arr.length === 0)  {
                _field_arr = _fields.filter( f => { return f.name && f.array && f.name.substr(0, (f.name.indexOf('_')>-1 ? f.name.indexOf('_') : f.name.length)) === field});
            }


            if (options.length === 0 && url !== undefined && url !== null && url !== "") {
                options = await new Request().Run(url, method, args, token);
               _FieldFunction("on_" + field, options);
            }


            if(_field_arr && _field_arr.length > 0) {
                //if (options.length > 0) {
                    _field_arr.map(_field => {
                        let v = GetDataValue(field);
                         if(v === undefined) v = _field.value;
                         if(options.length > 0) _field.value = (v && v !== undefined && v !== null ? v : options[0].id);
                         if(Array.isArray(_field.type.options) && options.length > 0) _field.type.options = options;
                    });
                //}
            }

           
            setFields(_fields);
        } catch (e) { 
            console.log(field, e);
        }
    }


    const SelectArray = async (field, options, url, method, args, token) => {
        try {
            let _fields = [...fields];
            let _field_arr = _fields.filter( f => { return f.name && f.name === field});

            if(_field_arr.length === 0) return;
            
            if (options.length === 0 && url !== undefined && url !== null && url !== "") {
                options = await new Request().Run(url, method, args, token);
               _FieldFunction("on_" + field, options);
            }


            if(_field_arr && _field_arr.length > 0) {
                if (options.length > 0) {
                    _field_arr.map(_field => {
                        let v = GetDataValue(field);
                         if(v === undefined) v = _field.value;
                         _field.value = (v && v !== undefined && v !== null ? v : options[0].id);
                         if(Array.isArray(_field.type.options)) _field.type.options = options;
                    });
                }
            }

           
            setFields(_fields);
        } catch (e) { 
            console.log(field, e);
        }
    }

/*
    const Select1 = async (field, options, url, method, args, token) => {
        try {
            let _fields = [...fields];
            let _field = _fields.find(f => f.name === field);
            if(_field === undefined) _field = _fields.find(f => f.name && f.name.substr(0, (f.name.indexOf('_')>-1 ? f.name.indexOf('_') : f.name.length)) === field);

            if (options.length === 0 && url !== undefined && url !== null && url !== "") {
                options = await new Request().Run(url, method, args, token);

               _FieldFunction("on_" + field, options);
            }

            if (options.length > 0) {
                let v = GetDataValue(field);
                if(v === undefined) v = _field.value;
                //console.log(2, v, field,  _field);
                _field.value = (v && v !== undefined && v !== null ? v : options[0].id);
            }

            _field.type.options = options;
            setFields(_fields);
        } catch (e) { 
            console.log(field, e);
        }
    }
*/

    const Value = (field, value, datavalue) => {
        try {
            
            let _fields = [...fields];
            let _field = _fields.find(f => f.name === field);
            
            if(_field.type.view === "datalist" && datavalue) {
                _field.data_value = datavalue;
            }  
            _field.value = value;
            //console.log(field, value);
            
            setFields(_fields);
        } catch(e) {
            console.log(e.message, field, value);
        }
    }


    const GetObject = (field) => {
        let _fields = [...fields];
        let _field = _fields.find(f => f.name === field);
        return _field;
    }


    const SetObject = (field, objectValue) => {
        let _fields = [...fields];
        let _field_list = _fields.filter(f => f.name === field || f.array === field);
        if(_field_list.length > 0) {
            _field_list.map( _field => {
                if(objectValue.mask) _field.mask = objectValue.mask;
                if(objectValue.title) _field.title = objectValue.title;
                if(objectValue.required!==undefined&&objectValue.required!==null) _field.required = objectValue.required;
                if(objectValue.width) _field.width = objectValue.width;
                if(objectValue.height) _field.height = objectValue.height;
                if(objectValue.type) _field.type = objectValue.type;
                if(objectValue.disabled!==undefined&&objectValue.disabled!==null) _field.disabled = objectValue.disabled;
                if(objectValue.visible!==undefined&&objectValue.visible!==null) _field.visible = objectValue.visible;
                if(objectValue.checked!==undefined&&objectValue.checked!==null) _field.checked = objectValue.checked;
                if(objectValue.value!==undefined&&objectValue.value!==null) _field.value = objectValue.value;
                if(objectValue.phvisible!==undefined&&objectValue.phvisible!==null) _field.phvisible = objectValue.phvisible;
                setFields(_fields);
            });
        }
        return _field_list;
    }


    const GetValue = (field) => {
        let _fields = [...fields];
       
        let _field = _fields.find(f => f.name === field);

        if (_field.type.view === "select" || _field.type.view === "select_input") {
            let _tmp = (_field.type.options).find(f => parseInt(f.id) === parseInt(_field.value));
            return _tmp
        } else {
            return _field.value || "";
        }
    }


    const GetDataValue = (field) => {
        //console.log(field, _data.current);
        return _data.current[field];
    }


    const GetOptions = async(field) => {
        let _fields = [...fields];
        let _field = _fields.find(f => f.name === field);
        if (_field.type.view === "select" || _field.type.view === "select_input") {
            return await _field.type.options;
        } else {
            return await "";
        }
    }


    const RequiredValidation = () => {
        let _fields = [...fields];
        for (let i = 0; i < _fields.length; i++) {
            //console.log(_fields[i].name, _fields[i].value);
            if ((_fields[i].value === "" || _fields[i].value === undefined || _fields[i].value === null) && _fields[i].required && (_fields[i].visible === true || _fields[i].visible === undefined)) {
                console.log(_fields[i].name);
                return false;
            } 
        }
        return true;
    }


    const Init = () => {
        if(!_loadedRef.current) {
            //console.log("OnFormLoading", props.url);
            _FieldFunction("OnFormLoading");
            _loadedRef.current = true;

            if(props.token) setToken(props.token);

            Promise.resolve(RequestGet(props.url))
                .then((data) => {
                    data.map((item, i) => {
                        if (item.type.view === "select" || item.type.view === "select_input") {
                            try {
                                if(item.type.options && item.type.options.length > 0) {
                                    //console.log(item.name, item.type.options[0].id);
                                    item.value = item.type.options[0].id;
                                }
                            } catch(e) {
                                //console.log(item.name, e);
                                item.value = ""; 
                            }
                        } else if(item.type.view === "checkbox") {
                            item.checked = false;
                        } else {
                            if(item.value === undefined || item.value === null) { item.value = "" };
                        }
                    });

                    setSource(data);
                    setFields(data);
                    //console.log("OnFormLoaded", props.url);
                    _FieldFunction("OnFormLoaded", data);
                });
        }
    }


    const Clear = (formloadDispatch) => {
        let _fields = [...fields];
        _fields.map((item, i) => {
            if (item.type.view === "select" || item.type.view === "select_input") {
                try {
                    item.value = item.type.options[0].id;
                } catch(e) {item.value = ""; }
            } else if(item.type.view === "checkbox") {
                item.checked = false;
            } else {
                item.value = "";
            }
        });
        setFields(_fields);
        if(formloadDispatch) _FieldFunction("OnFormLoaded", _fields);
    }


    const Load = async (data, field, value, type) => {
        let list_of_array = [];
        _data.current = data;
        //console.log("Load --------------------------------------------------------", data);
       
        wait_form = setTimeout(async() => {
            if (fields.length > 0) {
                //clearInterval(wait_form);
                let _fields = [...fields];

                if (field === "") {
                    _fields.map((field, i) => {
                        //console.log(field.type.view, field.name, data[field.name]);
                        if(field.array) {
                            if(list_of_array.indexOf(field.array) < 0) list_of_array.push(field.array);
                        } else if (field.type.view === "currency") {
                            let _tmp = UnCurrency(data[field.name]);
                            field.value = Currency(data[field.name]);
                            if(props.initial_trigger === true || props.initial_trigger === undefined) _FieldFunction(field.name, field.value);
                        } else if (field.type.view === "numbermask") {
                            let _tmp = UnCurrency(data[field.name]);
                            field.value = Number(data[field.name], field.maxlength, field.decimals);
                            if(props.initial_trigger === true || props.initial_trigger === undefined) _FieldFunction(field.name, field.value);
                        } else if (field.type.view === "radio") {
                            field.value = data[field.name];
                            if(field.value !== null && (props.initial_trigger === true || props.initial_trigger === undefined)) _FieldFunction(field.name, field.value.toString());
                        } else if (field.type.view === "date" || field.type.view === "datetime") {
                            field.value = moment(data[field.name]).format('YYYY-MM-DD');
                            if(props.initial_trigger === true || props.initial_trigger === undefined) _FieldFunction(field.name, field.value);
                        } else if (field.type.view === "datalist") {
                             //console.log(data, field.name,  field.field_value, data[field.field_value], data[field.field_data_value]);
                             field.value = data[field.field_value];
                             field.data_value = data[field.field_data_value];
                             if(props.initial_trigger === true || props.initial_trigger === undefined) _FieldFunction(field.name, field.value, field.data_value);
                        } else {
                            field.value = data[field.name];
                            if(props.initial_trigger === true || props.initial_trigger === undefined) _FieldFunction(field.name, field.value);
                        }
                    });
                } else {
                    let _field = _fields.find(f => f.name === field);
                    if (type === "insert") {
                        _field.type.options = data
                    } else if(type === "append") {
                        _field.type.options.push(data);
                    }
                    if (value != null) {
                        _field.value = value;
                        if(props.initial_trigger === true || props.initial_trigger === undefined) _FieldFunction(_field.name, field.value);
                    }
                }

                let _tmp = _fields;

                //console.log(_tmp)

                //console.log(list_of_array);
                
                list_of_array.map((item, i) => {
                    let _tmp_pre = FirstPush(item, (data[item].length), _tmp);
                    if(_tmp_pre) _tmp = _tmp_pre;
                });
                

                if(list_of_array.length>0) {
                    ArrayProcess(list_of_array, data, _fields);
                } else {
                    setFields(_tmp);
                }
            } else {
                //clearInterval(wait_form);
                //console.log(fields.length);
            }
        }, 300);
    }


    const ArrayProcess = (arrays, data, _fields) => {
        //console.log(arrays, data, _fields);

        let c = 0;
        if(_fields) {
            _fields.map((field, i) => {
                if(field.array) {
                    //console.log(field.name);
                    let array_data = _fields.filter(f => f.array === field.array);
                    let array_c = array_data.length/data[field.array].length;
                    let index = Math.floor(c/array_c);
                    //console.log(field.array, index, field.name.substr(0, field.name.lastIndexOf("_")));
                    try {
                        let value = data[field.array][index][field.name.substr(0, field.name.lastIndexOf("_"))];
                        field.value = value;
                    } catch(e){}
                    
                    //console.log(field.array, data[field.array].length, array_data.length, c, index, value);
                    c++;

                    if (field.type.view === "currency") {
                        field.value = Currency(field.value);
                        _FieldFunction(field.name, field.value);
                    } else if (field.type.view === "numbermask") {
                        field.value = Number(field.value);
                        _FieldFunction(field.name, field.value);
                    } else if (field.type.view === "radio") {
                        _FieldFunction(field.name, field.value.toString());
                    } else if (field.type.view === "date" || field.type.view === "datetime") {
                        field.value = moment(field.value).format('YYYY-MM-DD');
                        _FieldFunction(field.name, field.value);
                    } else {
                        _FieldFunction(field.name, field.value);
                    }

                    if(c >= array_data.length) c = 0;
                }
            });

            setTimeout(()=> {
                setFields(_fields);
            }, 100);
        }
    }


    const FirstPush = (array_name, count, _fields) => {
        //console.log(array_name, count, _fields);

        if(_fields) {
            let array_index = _fields.findIndex(f => f.array === array_name && f.name.indexOf("{index}") > -1);
            let array_source = _fields.filter(f => f.array === array_name && f.name.indexOf("{index}") > -1);
            let array_data = _fields.filter(f => f.array === array_name);

            /*console.log(_fields.length);
            console.log(array_index);
            console.log(array_source);
            console.log(array_data.length);*/


           
           

            if(count !== undefined) {
                count = parseInt(count);
                let rows = (array_data.length/array_source.length);
        
                if(count > 0) {
                    let j = count-rows;
                    let len_data = array_data.length;

                    for(let n=0; n<j; n++){
                        let copy_array_source = clone(array_source);
                        copy_array_source.map((item, i) => {
                            item.name = item.name.replace("{index}", (len_data/array_source.length));
                        }); 
                        
                        _fields.splice.apply(_fields, [(array_index+array_data.length), 0].concat(copy_array_source));
                        array_index += array_source.length;
                        len_data += array_source.length;
                    }


                    array_data = _fields.filter(f => f.array === array_name);
                    
       
                    if(array_data && array_data.length > 0) {
                        array_data.map((item, i) => {
                            let value = null;
                            try {
                                value = _data.current[array_name][Math.floor(i/array_source.length)][item.name.substr(0, (item.name.indexOf('_')>-1?item.name.indexOf('_'):item.name.length))]
                                item.value = value;
                                //console.log(item.name.substr(0, (item.name.indexOf('_')>-1?item.name.indexOf('_'):item.name.length)), item.value, item.name.substr((item.name.indexOf('_'))));
                            } catch(e) { }
        
                            if(value !== null) _FieldFunction(item.name.substr(0, (item.name.indexOf('_')>-1?item.name.indexOf('_'):item.name.length)), item.value, item.name.substr((item.name.indexOf('_'))));
                        });
                    }

                    return _fields;
                    
                }
            }
    }
    }


    const Push = async(array_name, count) => {
        let _fields = [...fields];
        let array_index = _fields.findIndex(f => f.array === array_name && f.name.indexOf("{index}") > -1);
        let array_source = _fields.filter(f => f.array === array_name && f.name.indexOf("{index}") > -1);
        let array_data = _fields.filter(f => f.array === array_name);

        /*console.log(array_name);
        console.log(_fields.length);
        console.log(array_index);
        console.log(array_source);
        console.log(array_data.length);*/

        if(count !== undefined) {
            count = parseInt(count);
            let rows = (array_data.length/array_source.length);
            //console.log(count, rows);

    
            if(count > 0) {
                if(count < rows && count >= 0) {
                    _fields.splice(array_index+((count)*array_source.length), array_data.length - ((count)*array_source.length));
                    setFields(_fields);
                } else if(count > rows) {
                    let j = count-rows;
                    let len_data = array_data.length;

                    for(let n=0; n<j; n++){
                        let copy_array_source = clone(array_source);
                        copy_array_source.map((item, i) => {
                            item.name = item.name.replace("{index}", (len_data/array_source.length));
                            //console.log(item.name, n, len_data, (len_data/array_source.length));
                        }); 
                        
                        _fields.splice.apply(_fields, [(array_index+array_data.length), 0].concat(copy_array_source));
                        array_index += array_source.length;
                        len_data += array_source.length;
                    }
                    setFields(_fields) 
                }
            }
        } else {
            let copy_array_source = clone(array_source);
            copy_array_source.map((item, i) => {
                item.name = item.name.replace("{index}", (array_data.length));
            }); 
            _fields.splice.apply(_fields, [array_index, 0].concat(copy_array_source));
            array_index++;

            setFields(_fields) 
        }
        //console.log(_fields);
    }



    const Remove = async(array_name, count = 1) => {
        let _fields = [...fields];
        let array_index = _fields.findIndex(f => f.array === array_name && f.name.indexOf("{index}") > -1);
        let array_source = _fields.filter(f => f.array === array_name && f.name.indexOf("{index}") > -1);
        let array_data = _fields.filter(f => f.array === array_name);

    }



    const ArrayLength = async(array_name) => {
        let _fields = [...fields];
        let array_index = _fields.findIndex(f => f.array === array_name && f.name.indexOf("{index}") > -1);
        let array_source = _fields.filter(f => f.array === array_name && f.name.indexOf("{index}") > -1);
        let array_data = _fields.filter(f => f.array === array_name);

        return  (array_data.length/array_source.length);
    }


    const DataLength = async(array_name) => {
        return _data.current[array_name].length || 0;
    }
    //#endregion Actions

  
    const PreProcFields = (fields) => {
        let obj = {};
        fields.map(item => {
            if(item.name !== undefined) {
                if(item.visible === undefined) item.visible = true;
                if(item.type.view === "checkbox"){
                    obj[item.name] = item.visible ? (item.checked || item.value === 1 ? 1 : 0) : 0;
                } else if(item.type.view === "date") {
                    obj[item.name] = item.visible ? (item.value === undefined ? "" : moment(item.value).format('YYYY-MM-DD')) : "";
                } else if(item.type.view === "datetime") {
                    obj[item.name] = item.visible ? (item.value === undefined ? "" : moment(item.value).format('YYYY-MM-DD HH:mm:ss')) : "";
                } else if(item.type.view === "datalist") {
                    obj[item.name] = item.visible ? (item.data_value === undefined ? "" : item.data_value) : "";
                } else {
                    obj[item.name] = item.visible ? (item.value === undefined ? "" : item.value) : "";
                }
            }
        });
        return obj;
    }


    const _HandleReset = (event) => {
        let _fields = [...fields];
        let _field = _fields.find(f => f.name === event.target.id);
        if(_field.type.view==='numbermask') {
            _field.value = "0,00";
        } else {
            _field.value = "";
        }
        setFields(_fields);
    }


    const _HandleChange = async(event) => {
        let _fields = [...fields];

        let _field = _fields.find(f => f.name === event.target.id);

        if (event.target.type == "checkbox") {
            //console.log(_field.name, event.target.checked);
            _field.checked = event.target.checked;
            _field.value = event.target.checked;
            setFields(_fields);
            _FieldFunction(event.target.id, event.target.checked);
        } else  if (event.target.type === "radio") {
            _field = _fields.find(f => f.name === event.target.name);
            _field.value = event.target.value;
           // _field.type.options.find(f => parseInt(f.id) === parseInt(event.target.value)).checked = true;
            setFields(_fields);
            _FieldFunction(event.target.name, event.target.value);
        } else {
            if (_field.mask != "" && _field.mask != null && _field.mask != undefined) {
                let result1 = event.target.value.replace(/[^\d]+/g, '');
                
                if(window.keyCode !== 8) {
                    let formatter = new StringMask(_field.mask);
                    result1 = formatter.apply(result1);
                } else {
                    let result2 = _field.value .replace(/[^\d]+/g, '');
                    if(result2 === result1) {
                        result1 =  result1.substr(0, result1.length-1);
                    }
                
                    let formatter = new StringMask(_field.mask);
                    result1 = formatter.apply(result1);
                    
                }
                _field.value = result1;
            } else {
                _field.value = event.target.value;
            }


            setFields(_fields);
            if(event.target.id !== "alert") {
                if(_field.array) {
                    //console.log(event.target.id, event.target.value, event.target.id.substr((event.target.id.indexOf('_'))));
                    _FieldFunction(event.target.id.substr(0, (event.target.id.indexOf('_')>-1?event.target.id.indexOf('_'):event.target.id.length)), event.target.value, event.target.id.substr((event.target.id.indexOf('_'))));
                } else {
                    _FieldFunction(event.target.id, event.target.value);
                }
            }
        }

        _FieldFunction("onFormGenerator");
    }


    const _HandleCurrencyChange = (event) => {
        let _fields = [...fields];
        let _field = _fields.find(f => f.name == event.target.id);

        var so = 0;
        var o = event.target.value.toString();
        if (o != null && o != undefined) {
            if (o.indexOf("$") > -1) {
                o = o.replace("R$", "");
                o = o.replace(" ", "");
                o = o.replace(",", "");
                o = o.replace(".", "");
                so = parseFloat(o / 100);
            } else {
                so = parseFloat(o / 100);
            }
        }
        if (isNaN(so)) so = 0;

        _field.value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(so);
        setFields(_fields);
        _FieldFunction(event.target.id, event.target.value);
        _FieldFunction("onFormGenerator");
    }


    const _HandleNumberChange = (event) => {
        let _fields = [...fields];
        let _field = _fields.find(f => f.name === event.target.id);
        let _decimals = 2;
        if(_field.decimals)  _decimals = _field.decimals;

        let so = 0;
        let o = event.target.value.toString();
        //console.log(_field.name, _field.value, o);

        let isNegative = o.indexOf("-") > -1;
        if(!isNegative && _field.maxlength && _field.maxlength> 0) {
            o = o.substr(0, _field.maxlength-1);
        }
    
        o = o.replace('-', '');

        if (o !== null && o !== undefined) {
            if(o.indexOf(',') > -1) {
                if(window.keyCode === 8) {
                    o = o.replace(',', '.');
                    so = parseFloat(o)/10;
                } else {
                    o = o.replace(',', '.');
                    so = parseFloat(o)*10;
                }
            } else {
                o = parseFloat(o);
                let preset = (window.keyCode === '' || window.keyCode === undefined);
                if(o>=1) so = parseFloat(o/(Math.pow(10,(preset?0:_decimals))));
            }
        }
        
        if (isNaN(so)) so = 0;


        let r = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: _decimals, maximumFractionDigits: _decimals }).formatToParts(so).map(
            p => p.type !== 'literal' && p.type !== 'currency' ? p.value : ''
        ).join('');
    
        _field.value =  (isNegative?'-':'') + r;

        /*
        if(!isNegative && _field.maxlength) {
            o = o.substr(0, _field.maxlength-1);
        }

        if (o != null && o != undefined) {
            o = o.replace(/[^0-9]/g, '');
            so = parseFloat(o/Math.pow(10,_decimals));
        }
        if (isNaN(so)) so = 0;

        _field.value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: _decimals, maximumFractionDigits: _decimals }).format(so);
        _field.value =  (isNegative?'-':'') +_field.value.replace(/[^0-9.,]/g, '');
        */

        
        setFields(_fields);
        _FieldFunction(event.target.id, _field.value);
        _FieldFunction("onFormGenerator");
    }


    const _HandleDatalistChange = (event) => {
        let _fields = [...fields];
        let _field = _fields.find(f => f.name === event.target.id);
        _field.value = event.target.value;
        setFields(_fields);
        
        clearTimeout(datalist_timer[_field.name]);

        console.log( datalist_result[_field.name] );

        datalist_timer[_field.name] = setTimeout(() => {
            if(datalist_result[_field.name] && datalist_result[_field.name].toString() !== "error" && datalist_result[_field.name].length > 0) {
                let p0 = event.target.value.indexOf(". ");
                if(p0 > -1) {
                    let _id = event.target.value.substr(0, p0);
                    let _name = event.target.value.substr(p0+2);
                    console.log(_id+"|"+_name);

                    let finded = datalist_result[_field.name].find(f => f.name === _name && f.id === parseInt(_id));
                    if(finded) {
                         console.log(finded);
                        _field.data_value = finded.id;
                        _FieldFunction(event.target.id, _field.value, finded);
                        _FieldFunction("onFormGenerator");
                    } else {
                        _field.data_value = "";
                        let min_len = _field.minlen || 3;
                        if(_field.value.length > min_len && _field.url && _field.url !== "") {
                            Promise.resolve(new Request().Run(_field.url, "POST", Object.assign(_field?.parms||{}, {word: _field.value}), mtoken, 0)).then(async(data) => {
                                datalist_result[_field.name] = data;
                                await Select(_field.name, data) ;
                            }).catch(() => console.log("forme error: " + _field.name))
                        }
                    }
                } else {
                    _field.data_value = "";
                    let min_len = _field.minlen || 3;
                    if(_field.value.length > min_len && _field.url && _field.url !== "") {
                        Promise.resolve(new Request().Run(_field.url, "POST", Object.assign(_field?.parms||{}, {word: _field.value}), mtoken, 0)).then(async(data) => {
                            datalist_result[_field.name] = data;
                            await Select(_field.name, data) ;
                        }).catch(() => console.log("forme error: " + _field.name))
                    }
                }
            } else {
                _field.data_value = "";
                let min_len = _field.minlen || 3;
                if(_field.value.length > min_len && _field.url && _field.url !== "") {
                    Promise.resolve(new Request().Run(_field.url, "POST", Object.assign(_field?.parms||{}, {word: _field.value}), mtoken, 0)).then(async(data) => {
                        datalist_result[_field.name] = data;
                        await Select(_field.name, data) ;
                    }).catch(() => console.log("forme error: " + _field.name))
                }
            }
        }, 700);

      


        /*let finded = _field?.type?.options?.find(f => f.name === event.target.value);
        setFields(_fields);




        if(finded) {
            _field.data_value = finded.id;
        } else {
            _field.data_value = "";
            let min_len = _field.minlen || 3;
            //console.log(_field.value, _field.url);

            if(_field.value.length > min_len && _field.url && _field.url !== "") {
                Promise.resolve(new Request().Run(_field.url, "POST", Object.assign(_field?.parms||{}, {word: _field.value}), mtoken, 0)).then(async(data) => {
                    //console.log(data);
                    await Select(_field.name, data) ;
                }).catch(() => console.log("forme error: " + _field.name))
            }
        }
       
        _FieldFunction(event.target.id, _field.value, finded);
        _FieldFunction("onFormGenerator");*/
    }


    const _HandleDoubleClick = (event) => {
        let _fields = [...fields];
        let _field = _fields.find(f => f.name === event.target.id);
        _field.value = "";
        if(_field.type.view === "datalist") _field.data_value = "";
        setFields(_fields);
    }

    
    const _Height = (field) => {
        if(field.height === undefined || field.height === null || field.height === null){
            if(field.type.view === 'textarea'){
                return "100px";
            }
        } else {
            return (parseInt(field.height) + 40) + "px"
        }
    }


    const _InlineComponent = (field) => {
        if (field.inline === null || field.inline === undefined || field.inline === "" || field.inline === false) return (
            <>
            {/*<div className={'field ' +  (field.type.view?'css_'+field.type.view:'') + ' ' + (field.classes?field.classes:'')} style={{ height: _Height(field)}} >
                <label className={'fg-label' + (field.type.view === 'separator' || field.type.view === 'separatorbar' || field.type.view === 'empty_space' || field.type.view === 'button' || field.headless===false ? ' hide' : '')}>{field.title}<em className={!field.required ? 'hide' : ''}>*</em> {field.doubledot===false||field.type.view==='checkbox'?'':':'}&nbsp;<sup className={field.help !== '' && field.help !== null && field.help !== undefined ? '' : 'hide'}> [ <a title={field.helpcontent} onClick={() => _FieldFunction('sup_' + field.name, field.value)}>{field.help}</a> ]</sup></label>
                <label className={'fg-label' + ((field.type.view !== 'empty_space' && field.type.view !== 'button') || field.headless ? ' hide' : ' ')}>&nbsp;</label>
                <div style={{ maxHeight: '35px' }}>
                    {_FieldComponent(field)}
                </div>
            </div>*/}

            {_FieldComponent(field)}
            </>
        )
    }


    const _FieldFunction = (func, field, posfix) => {
        props.execRef(func, field, posfix);
    }


    const _HandlerPwdReveal = (field) => {
        //$("." + field.name + "-icon").toggleClass("bx bx-low-vision");
        var input = $("#"+ field.name);
        if (input.attr("type") === "password") {
            input.attr("type", "text");
            $("." + field.name + "-icon").removeClass("bx-show");
            $("." + field.name + "-icon").addClass("bx-hide");
        } else {
            input.attr("type", "password");
            $("." + field.name + "-icon").removeClass("bx-hide");
            $("." + field.name + "-icon").addClass("bx-show");
        }
    }


    const _HandleFocus = (event) => {
        window.currentElementFocus = event.target;
    }


    const _FieldComponent = (field) => {
        switch (field.type.view) {
            case 'empty_space':
                return (
                    <div className={field.name} id={field.name}>&nbsp;</div>
                )
            case 'separator':
                return (
                    <div>
                        <hr className={field.name} />
                    </div>
                )
            case 'separatorbar':
                return (
                    <div className={'ibox-content ibox-heading separatorbar ' + field.name} style={{ padding: '10px', fontSize: '16px' }}>
                         <div className="separatorbar_title"><i className={field.icon}></i> {field.title}</div>
                         <div className="separatorbar_content">
                            
                         </div>

                         <div className="separatorbar_actions">
                            
                        </div>
                    </div>
                )
            case 'input':
                return (
                    <>
                    {/*<div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="text" required={field.required} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} mask={field.mask} placeholder={field.placeholder} />
                    </div>*/}
                   
                        <div className={'form-floating  mb-3 '+ (field.icon !== undefined && field.icon !== '' ? 'form-floating-custom':'')}>
                            <input type="text" className={'form-control ' + (field.required && field.value === '' && 'form-field-required_empty')} id={field.name} name={field.name} required={field.required} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} mask={field.mask} placeholder={field.placeholder} onDoubleClick={_HandleDoubleClick} autoComplete="off"/>
                            <label htmlFor="input-username">{field.title} <em className={!field.required ? 'hide' : ''}>*</em></label>
                            <div className="form-floating-icon">
                                <i className={field.icon}></i>
                            </div>
                        </div>
                    </>
                )

            case 'datalist':
                return (<>
                    <div className={'form-floating  mb-3 '+ (field.icon !== undefined && field.icon !== '' ? 'form-floating-custom':'')}>
                        <input type="text" className={'form-control ' + (field.required && field.value === '' && 'form-field-required_empty')} id={field.name} name={field.name} list={field.name + '_datalist'} onclick="this.setSelectionRange(0, this.value.length)"  required={field.required} value={field.value} onChange={_HandleDatalistChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} mask={field.mask} placeholder={field.placeholder} autoComplete="off"/>
                        <label htmlFor="input-username">{field.title} <em className={!field.required ? 'hide' : ''}>*</em></label>
                        <div className="form-floating-icon">
                            <i className={field.icon}></i>
                        </div>
                    </div>

                    <datalist id={field.name + '_datalist'}>
                       {
                            Array.isArray(field.type.options) && field.type.options.length > 0 && field.type.options.map((option, i) => (
                                <option key={field.name +'opti_' + i} value={option.id + '. ' + option.name} selected={option.selected == true}>{option[field.field_option]}</option>
                            ))
                        }
                    </datalist>
                </>)
                
            case 'email':
                return (
                    <>
                    {/*<div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="email" required={field.required} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} mask={field.mask} placeholder={field.placeholder} />
                    </div>*/}

                        <div className={'form-floating mb-3 ' + (field.icon !== undefined && field.icon !== '' ? 'form-floating-custom':'')}>
                            <input type="email" className={'form-control ' + (field.required && field.value === '' && 'form-field-required_empty')}  id={field.name} name={field.name} required={field.required}  defaultValue={field.value} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} mask={field.mask} placeholder={field.placeholder} />
                            <label htmlFor="input-username">{field.title} <em className={!field.required ? 'hide' : ''}>*</em></label>
                            <div className="form-floating-icon">
                                <i className={field.icon}></i>
                            </div>
                        </div>
                    </>
                )
            case 'currency':
                return (
                    <>
                    {/*<div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="text" required={field.required} value={field.value} onChange={_HandleCurrencyChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} mask={field.mask} placeholder={field.placeholder} onDoubleClick={_HandleReset}/>
                    </div>*/}

                    <div className={'form-floating  mb-3 '+ (field.icon !== undefined && field.icon !== '' ? 'form-floating-custom':'')}>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name + (field.required && field.value === '' && ' form-field-required_empty')} type="text" required={field.required}  defaultValue={field.value} value={field.value} onChange={_HandleCurrencyChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} mask={field.mask} placeholder={field.placeholder} onDoubleClick={_HandleReset}/>
                        <label htmlFor="input-username">{field.title} <em className={!field.required ? 'hide' : ''}>*</em></label>
                        <div className="form-floating-icon">
                            <i className={field.icon}></i>
                        </div>
                    </div>
                    </>
                )

            case 'number':
                return (
                    <>
                    {/*<div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="number" required={field.required} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} min={field.min} max={field.max} step={field.step}/>
                    </div>*/}

                    <div className={'form-floating  mb-3 '+ (field.icon !== undefined && field.icon !== '' ? 'form-floating-custom':'')}>
                    <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name + (field.required && field.value === '' && ' form-field-required_empty')}  type="number" required={field.required} defaultValue={field.value} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} min={field.min} max={field.max} step={field.step}/>
                            <label htmlFor="input-username">{field.title} <em className={!field.required ? 'hide' : ''}>*</em></label>
                            <div className="form-floating-icon">
                                <i className={field.icon}></i>
                            </div>
                        </div>
                    </>
                )

            
            case 'numbermask':
                return (
                    <>
                    {/*<div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="number" required={field.required} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} min={field.min} max={field.max} step={field.step}/>
                    </div>*/}

                    <div className={'form-floating  mb-3 '+ (field.icon !== undefined && field.icon !== '' ? 'form-floating-custom':'')}>
                    <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name + (field.required && field.value === '' && ' form-field-required_empty')}  type="text" required={field.required} defaultValue={field.value}  value={field.value} onChange={_HandleNumberChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} maxlength={field.maxlength} onDoubleClick={_HandleReset}/>
                            <label htmlFor="input-username">{field.title}<em className={!field.required ? 'hide' : ''}>*</em></label>
                            <div className="form-floating-icon">
                                <i className={field.icon}></i>
                            </div>
                        </div>
                    </>
                )

            case 'date':
                return (
                    <>
                    {/*<div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="date" required={field.required} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} />
                    </div>*/}

                        <div className={'form-floating  mb-3 '+ (field.icon !== undefined && field.icon !== '' ? 'form-floating-custom':'')}>
                            <input type="date" className={'form-control ' + (field.required && field.value === '' && 'form-field-required_empty')} id={field.name} name={field.name} required={field.required} defaultValue={field.value} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} mask={field.mask} placeholder={field.placeholder} />
                            <label htmlFor="input-username">{field.title} <em className={!field.required ? 'hide' : ''}>*</em></label>
                            <div className="form-floating-icon">
                                <i className={field.icon}></i>
                            </div>
                        </div>
                    </>
                )

            case 'datetime':
                return (
                    <>
                    {/*<div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="datetime-local" required={field.required} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} min={field.min} max={field.max}/>
                    </div>*/}

                        <div className={'form-floating  mb-3 '+ (field.icon !== undefined && field.icon !== '' ? 'form-floating-custom':'')}>
                            <input type="datetime-local" className={'form-control ' + (field.required && field.value === '' && 'form-field-required_empty')} id={field.name} name={field.name} required={field.required} defaultValue={field.value} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} mask={field.mask} placeholder={field.placeholder} />
                            <label htmlFor="input-username">{field.title} <em className={!field.required ? 'hide' : ''}>*</em></label>
                            <div className="form-floating-icon">
                                <i className={field.icon}></i>
                            </div>
                        </div>
                    </>
                )

            case 'password':
                return (
                    <>
                    {/*<div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="password"  required={field.required} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} placeholder={field.placeholder} autocomplete="off"/>
                        <span className={'bx bx-show ' + field.name +'-icon'}  onClick={() => _HandlerPwdReveal(field)} style={{ float: 'right', marginRight: '10px', marginTop: '-34px', position: 'relative', cursor: 'pointer', fontSize: '23px', zIndex: 2}}></span>
                    </div>*/}

                        <div className={'form-floating  mb-3 '+ (field.icon !== undefined && field.icon !== '' ? 'form-floating-custom':'')}>
                            <input type="password" className={'form-control ' + (field.required && field.value === '' && 'form-field-required_empty')} id={field.name} name={field.name} required={field.required} defaultValue={field.value} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} mask={field.mask} />
                            <label htmlFor="input-username">{field.title} <em className={!field.required ? 'hide' : ''}>*</em> <sup className={(field.phvisible===false?'hide':'')}>{field.placeholder}</sup></label>
                            
                            <div className="form-floating-btn">
                                <button type="button" className="btn btn-outline-primary" onClick={() => _HandlerPwdReveal(field)} >
                                    <i className={'fa fa-fw fa-eye ' + field.name +'-icon'}></i>
                                </button>
                            </div>
                            
                            <div className="form-floating-icon">
                                <i className={field.icon}></i>
                            </div>
                        </div>
                    </>
                )

            case 'checkbox':
                return (
                    <>
                    {/*<div>
                        <input id={field.name} name={field.name} style={{ width: '25px', height: '25px' }} className={'form-control fg-input ' + field.name} type="checkbox" required={field.required} checked={field.value||field.checked} onChange={_HandleChange} disabled={field.disabled} />
                    </div> */}
                        <div style={{margin:'1px 8px 1px 8px'}}>
                            <label className="form-check-label" htmlFor={field.name}> {field.title} </label><br />
                            <div className="form-check _form-switch form-switch-md mb-2">
                                <input id={field.name} name={field.name} style={{ width: '25px', height: '25px' }} className={'form-check-input ' + field.name + (field.required && field.value === '' && ' form-field-required_empty')} type="checkbox" required={field.required} checked={field.value||field.checked} onChange={_HandleChange} disabled={field.disabled} />
                            </div> 
                        </div>  
                    </>
                )

            case 'radio':
                return (
                    <div>
                        {field.type.options.map((option, i) => (
                            <div key={'radio_' + field.name + i} className="radio">
                                <input key={'rad_' + field.name + i} id={field.name+option.id} name={field.name} value={option.id.toString()} type="radio"  style={{ width: '18px', height: '18px' }} required={field.required} checked={field.value.toString()===option.id.toString()} onChange={_HandleChange} disabled={field.disabled}/> <label htmlFor={field.name+option.id} >{option.name} </label>
                            </div>
                        ))}
                    </div>
                )

            case 'textarea':
                return (
                    <>
                    {/*<div>
                        <textarea id={field.name} name={field.name} style={{ height: (field.height || '100px') }} className={'form-control fg-input ' + field.name} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)}>{field.value}</textarea>
                    </div>*/}

                        <div className={'form-floating  mb-3 '+ (field.icon !== undefined && field.icon !== '' ? 'form-floating-custom':'')}>
                            <textarea id={field.name} name={field.name} style={{ height: (field.height || '100px') }} className={'form-control fg-input ' + field.name+ (field.required && field.value === '' && ' form-field-required_empty')} defaultValue={field.value}  value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)}>{field.value}</textarea>
                            <label htmlFor="input-username">{field.title} <em className={!field.required ? 'hide' : ''}>*</em></label>
                            <div className="form-floating-icon">
                                <i className={field.icon}></i>
                            </div>
                        </div>
                    </>
                )

            case 'select':
                return (
                    <>
                    {/*<div className="select">
                        <select id={field.name} name={field.name} className={'form-control fg-input ' + field.name} value={field.value} onChange={_HandleChange} disabled={field.disabled} required={field.required}>
                            {field.type.options.map((option, i) => (
                                <option key={'opt_' + i} value={option.id} selected={option.selected == true}>{option.name}</option>
                            ))}
                        </select>
                    </div>*/}

                    
                        <div className={'form-floating mb-3 ' + (field.icon !== undefined && field.icon !== '' ? 'form-floating-custom':'')}>
                            <select id={field.name} name={field.name} className={'form-control fg-input pointer ' + field.name+ (field.required && field.value === '' && ' form-field-required_empty')} aria-label={field.name} aria-describedby="basic-addon2" value={field.value} disabled={field.disabled} onChange={_HandleChange} required={field.required}>
                                {field.type.options.map((option, i) => (
                                    <option key={field.name +'opti_' + i} value={option.id} selected={option.selected == true}>{option.name}</option>
                                ))}
                            </select>
                            <label htmlFor="input-username">{field.title} <em className={!field.required ? 'hide' : ''}>*</em></label>
                            <div className="form-floating-icon">
                                <i className={field.icon}></i>
                            </div>
                        </div>
                    </>
                )

            case 'select_input':
                return (
                    <>
                

                        <div className={'form-floating mb-3  ' + (field.icon !== undefined && field.icon !== '' ? 'form-floating-custom':'')}>
                            <select id={field.name} name={field.name} className={'form-control pointer fg-input ' + field.name+ (field.required && field.value === '' && ' form-field-required_empty')} aria-label={field.name} aria-describedby="basic-addon2" value={field.value} disabled={field.disabled} onChange={_HandleChange} required={field.required}>
                                {field.type.options.map((option, i) => (
                                    <option key={field.name +'opti_' + i} value={option.id} selected={option.selected == true}>{option.name}</option>
                                ))}
                            </select>
                            <label htmlFor="input-username">{field.title} <em className={!field.required ? 'hide' : ''}>*</em></label>
                            <div className="form-floating-btn">
                                <button type="button" className="btn btn-outline-secondary" onClick={() => _FieldFunction('sup_' + field.name, field.value)} disabled={field.disabled} >
                                    <i className="fa fa-edit"></i>
                                </button>

                                <button type="button" className="btn btn-outline-primary" onClick={() => _FieldFunction('new_' + field.name, field.value)} disabled={field.disabled} >
                                    <i className="fa fa-plus"></i>
                                </button>
                            </div>
                            <div className="form-floating-icon">
                                <i className={field.icon}></i>
                            </div>
                        </div>
                    </>
                )

            case 'button':
                return (
                    <div>
                        <button type="button" id={field.name} name={field.name} style={{ height: (field.height || '100px') }} className={'form-control fg-input ' + field.name} value={field.value} onChange={_HandleChange} disabled={field.disabled} onClick={() => _FieldFunction('click_' + field.name, field.value)} dangerouslySetInnerHTML={ {__html: field.title} }></button>
                    </div>
                )

            case 'image':
                return (
                    <div>
                        <img id={field.name} src={cdn+field.value} className={' ' + field.name + ' ' + (field.value!==''?'':'hide')} style={{height: (field.height || '100px'), width:(field.width || '100px')}} onClick={() => _FieldFunction('click_' + field.name, field.value)}/>
                    </div>
                )
        }
    }


    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    const clone = (obj) => {
        var copy;
    
        // Handle the 3 simple types, and null or undefined
        if (null == obj || "object" != typeof obj) return obj;
    
        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }
    
        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (var i = 0, len = obj.length; i < len; i++) {
                copy[i] = clone(obj[i]);
            }
            return copy;
        }
    
        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
            }
            return copy;
        }
    
        throw new Error("Unable to copy obj! Its type isn't supported.");
    }

    return (
        <div style={{ width: '100%' }}>
            {fields.map((field, i) => (
                <div key={'field_' + i}>
                    <div>
                        <div id={'_' + field.name} className={'form-group pull-left '}  style={{ width: field.width || 0, padding: '3px', marginTop: field.margintop || 0, display: (field.visible === false ? 'none' : 'block') }}>
                        {_InlineComponent(field)}
                        </div>
                    </div>
                    <div className={(field.end===true?'clear':'')} />
                </div>
            ))}
            <div className="clear"></div>
        </div>
    )
});


