


const application_endpoints_list = [
    {endpoint:"default", servers: ["https://localhost:7108/", "https://localhost:7108/", "https://localhost:7108/"]},
];



/*
const application_endpoints_list = [
    {endpoint:"default", servers: ["https://lifequery.org/", "https://lifequery.org/", "https://lifequery.org/"]},
];
*/



export const GetServer = async(server_index) => {

}


export const ServerURL = (url) => {
    //if(window.location.href.indexOf("localhost") > -1) return url;
    if(url.toLowerCase().indexOf("http") === -1 && url.substr(0,1) !== "/" && url.substr(0,2) !== "./" ) url = AppServer(url) + url;
    return url;
}


export const AppServer = (endpoint = "") => {
    let _endpoint = application_endpoints_list.find(e => e.endpoint === endpoint);
    if(!_endpoint) {
        _endpoint = application_endpoints_list.find(e => e.endpoint === "default");
    }

    if(_endpoint) {
        //console.log(_endpoint);
        let id_server = rIndex(0, _endpoint.servers.length-1);
        //console.log("app_server", _endpoint,endpoint, _endpoint.servers[id_server]);
        return _endpoint.servers[id_server];
    } else {
        return "";
    }
}





const rIndex = (min, max) => {
    min = Math.floor(min);
    max = Math.ceil(max) + 0.9;
    //return Math.floor(Math.random() * (max - min)) + min;
    return  Math.floor((Math.random() * 1000000)%(max - min)) + min;
}
